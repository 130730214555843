<template>
  <v-dialog v-model="showPaymentMethodDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <PaymentMethodForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          :ref="getFormRef()"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="handleUpdated"
          @click="confirmDelete = true"
          small
          class="red"
          dark
          color="danger"
          >{{ $t("Delete") }}</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          @click="showPaymentMethodDialog = false"
          >{{ $t("Close") }}</v-btn
        >
        <v-btn class="blue" @click="resetForm">{{ $t("Reset") }}</v-btn>
        <v-btn class="blue" color="primary" @click="sendForm">{{
          $t("Submit")
        }}</v-btn>
      </v-card-actions>
      <ConfirmDelete
        :handle-delete="del"
        :visible="confirmDelete"
        @close="confirmDelete = false"
        v-if="handleUpdated"
      />
    </v-card>
    <Loading :visible="isLoading" />
  </v-dialog>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import PaymentMethodForm from "./Form";
import NotificationMixin from "../../mixins/NotificationMixin";
import ConfirmDelete from "../ConfirmDelete";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";

const servicePrefix = "PaymentMethod";

const { mapFields } = createHelpers({
  getterType: "paymentMethod/getField",
  mutationType: "paymentMethod/updateField",
});

export default {
  name: "PaymentMethodDialog",
  servicePrefix,
  mixins: [NotificationMixin],
  components: {
    Loading,
    PaymentMethodForm,
    ConfirmDelete,
  },
  props: {
    value: Array,
    title: {
      type: String,
      required: true,
    },
    handleCreated: {
      type: Function,
      required: false,
    },
    handleUpdated: {
      type: Function,
      required: false,
    },
    handleDeleted: {
      type: Function,
      required: false,
    },
    paymentMethodItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      item: {},
      confirmDelete: false,
      createMessage: this.$t("successfullyCreated"),
      updateMessage: this.$t("successfullyUpdated"),
      deleteMessage: this.$t("successfullyDeleted"),
    };
  },
  computed: {
    ...mapFields([
      "error",
      "isLoading",
      "created",
      "deleted",
      "violations",
      "updated",
      "byId",
    ]),
    ...mapGetters("paymentMethod", ["find"]),
    formRefName() {
      if (this.handleUpdated) {
        return "updateForm";
      }
      return "createForm";
    },
    formRef() {
      if (this.handleUpdated) {
        return this.$refs.updateForm;
      }
      return this.$refs.createForm;
    },
    showPaymentMethodDialog: {
      get() {
        return (
          this.value.indexOf(
            typeof this.paymentMethodItem["@id"] === "undefined"
              ? "new"
              : this.paymentMethodItem["@id"]
          ) > -1
        );
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    ...mapActions("paymentMethod", {
      create: "create",
      update: "update",
      resetData: "resetData",
      retrieve: "load",
      deleteItem: "del",
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.formRef.$v.item.$model;
        if (this.handleUpdated) {
          if (this.paymentMethodItem.notPersisted !== true) {
            this.update(model);
          } else {
            this.handleUpdated(model);
            this.showPaymentMethodDialog = false;
          }
        }
        if (this.handleCreated) {
          if (!this.$refs.createForm) {
            this.create(model);
          } else {
            this.handleCreated(model);
            this.resetForm();
            this.showPaymentMethodDialog = false;
          }
        }
      }
    },
    reset() {
      this.formRef.$v.$reset();
    },
    resetForm() {
      this.item = { ...this.paymentMethodItem };
      this.formRef.$v.$reset();
    },
    del() {
      if (!this.paymentMethodItem.notPersisted) {
        this.deleteItem(this.paymentMethodItem).then(() => {
          this.showPaymentMethodDialog = false;
          this.showMessage(this.deleteMessage);
          this.handleDeleted(this.paymentMethodItem);
        });
      } else {
        this.handleDeleted(this.paymentMethodItem);
      }
    },
    isValidForm() {
      this.formRef.$v.$touch();
      return !this.formRef.$v.$invalid;
    },
    getFormRef() {
      return this.formRefName;
    },
  },
  watch: {
    created(created) {
      if (!created) {
        return;
      }
      this.showMessage(this.createMessage);
      if (this.handleCreated) {
        this.handleCreated(this.created);
        this.resetForm();
        this.showPaymentMethodDialog = false;
      }
    },
    updated() {
      this.showMessage(this.updateMessage);
      if (this.handleUpdated) {
        this.handleUpdated(this.updated);
        this.showPaymentMethodDialog = false;
      }
    },
    error(message) {
      message && this.showError(message);
    },
    deleteError(message) {
      message && this.showError(message);
    },
  },
  created() {
    this.item = { ...this.paymentMethodItem };
  },
};
</script>
