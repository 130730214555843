<template>
  <v-row v-if="values">
    <v-col cols="12" md="6" v-for="(paymentMethod, index) in values" :key="`paymentMethod-${index}`">
      <v-card class="mt-5">
        <v-card-title>{{ paymentMethod.name }}</v-card-title>
        <v-card-text>{{ paymentMethod.text }}<br /></v-card-text>
        <v-card-actions>
          <v-btn
              color="primary"
              small
              text
              @click.prevent="dialogControl(paymentMethod['@id'])"
          >
            {{ $t("Edit") }}
          </v-btn>
          <PaymentMethodDialog
              v-model="showPaymentMethodDialogForm"
              :paymentMethodItem="paymentMethod"
              :handle-updated="onPaymentMethodUpdated"
              :handle-deleted="onPaymentMethodDeleted"
              :title="$t('EditPaymentMethod')"
              @close="dialogControl(paymentMethod['@id'], true)"
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import PaymentMethodDialog from "../../components/paymentMethod/PaymentMethodDialog";

export default {
  name: "PaymentMethodCard",
  components: {
    PaymentMethodDialog,
  },
  props: {
    values: {
      type: Array,
    },
  },
  data() {
    return {
      showPaymentMethodDialogForm: [],
      showTest: false,
    };
  },
  methods: {
    onPaymentMethodUpdated(updatedPaymentMethod) {
      this.values[
          this.values.findIndex((el) => el["@id"] === updatedPaymentMethod["@id"])
          ] = updatedPaymentMethod;
    },
    onPaymentMethodDeleted(deletedPaymentMethod) {
      this.values.splice(
          this.values[
              this.values.findIndex((el) => el["@id"] === deletedPaymentMethod['@id'])
              ],
          1
      );
    },
    dialogControl(id, close = false) {
      if (!close) {
        this.showPaymentMethodDialogForm.push(id);
      } else {
        this.showPaymentMethodDialogForm.splice(
            this.showPaymentMethodDialogForm.indexOf(id),
            1
        );
      }
    },
  },
};
</script>
