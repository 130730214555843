<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
              :error-messages="nameErrors"
              :label="$t('name')"
              @blur="$v.item.name.$touch()"
              @input="$v.item.name.$touch()"
              v-model="item.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
              :error-messages="levelErrors"
              :label="$t('dunningLevel')"
              @blur="$v.item.level.$touch()"
              @input="$v.item.level.$touch()"
              v-model.number="item.level"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :error-messages="feeErrors"
            :label="$t('dunningFee')"
            @blur="$v.item.fee.$touch()"
            @input="$v.item.fee.$touch()"
            v-model.number="item.fee"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <InputEditor
              v-model="item.text"
              :error-messages="textErrors"
              :label="$t('text')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="timeRangeErrors"
            :label="$t('timeRangeDays')"
            @blur="$v.item.timeRange.$touch()"
            @input="$v.item.timeRange.$touch()"
            v-model.number="item.timeRange"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import has from "lodash/has";
import { required } from "vuelidate/lib/validators";
import InputEditor from "@/components/InputEditor";

export default {
  name: "DunningLevelForm",
  mixins: [validationMixin],
  components:{
    InputEditor
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      required: true,
    },

    initialValues: {
      type: Object,
      default: () => {},
    },

    handleSubmit: {
      type: Function,
      required: false,
    },

    handleReset: {
      type: Function,
      required: false,
    },
  },
  mounted() {},
  data() {
    return {
      name: null,
      text: null,
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    feeErrors() {
      const errors = [];
      if (!this.$v.item.fee.$dirty) {
        return errors;
      }
      has(this.violations, "fee") && errors.push(this.violations.fee);
      !this.$v.item.fee.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    levelErrors() {
      const errors = [];
      if (!this.$v.item.level.$dirty) {
        return errors;
      }
      has(this.violations, "level") && errors.push(this.violations.level);
      !this.$v.item.level.required && errors.push(this.$t("Field is required"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.item.name.$dirty) {
        return errors;
      }
      has(this.violations, "name") && errors.push(this.violations.name);
      !this.$v.item.name.required && errors.push(this.$t("Field is required"));
      return errors;
    },
    textErrors() {
      const errors = [];
      if (!this.$v.item.text.$dirty) {
        return errors;
      }
      has(this.violations, "text") && errors.push(this.violations.text);
      !this.$v.item.text.required && errors.push(this.$t("Field is required"));
      return errors;
    },
    timeRangeErrors() {
      const errors = [];
      if (!this.$v.item.timeRange.$dirty) {
        return errors;
      }
      has(this.violations, "timeRange") && errors.push(this.violations.timeRange);
      !this.$v.item.timeRange.required && errors.push(this.$t("Field is required"));
      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    },
  },
  validations: {
    item: {
      fee: {
        required,
      },
      level: {
        required,
      },
      name: {
        required,
      },
      text: {
        required,
      },
      timeRange: {
        required,
      },
    },
  },
};
</script>
