<template>
  <v-row v-if="values">
    <v-col cols="12" md="6" v-for="(dunningLevel, index) in values" :key="`dunningLevel-${index}`">
      <v-card class="mt-5">
        <v-card-title>{{ dunningLevel.name }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="5" lg="4" class="py-0">
              <strong>{{ $t('dunningLevel') }}:</strong>
            </v-col>
            <v-col cols="auto" class="py-0">
              {{ dunningLevel.level }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" lg="4" class="py-0">
              <strong>{{ $t('dunningFee') }}:</strong>
            </v-col>
            <v-col cols="auto" class="py-0">
              {{ dunningLevel.fee }}€
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" lg="4" class="py-0">
              <strong>{{ $t('timeRange') }}:</strong>
            </v-col>
            <v-col cols="auto" class="py-0">
              {{ dunningLevel.timeRange }} Tage
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="primary"
              small
              text
              @click.prevent="dialogControl(dunningLevel['@id'])"
          >
            {{ $t("Edit") }}
          </v-btn>
          <DunningLevelDialog
              v-model="showDunningLevelDialogForm"
              :dunningLevelItem="dunningLevel"
              :handle-updated="onDunningLevelUpdated"
              :handle-deleted="onDunningLevelDeleted"
              :title="$t('EditDunningLevel')"
              @close="dialogControl(dunningLevel['@id'], true)"
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import DunningLevelDialog from "../../components/dunningLevel/DunningLevelDialog";

export default {
  name: "DunningLevelCard",
  components: {
    DunningLevelDialog,
  },
  props: {
    values: {
      type: Array,
    },
  },
  data() {
    return {
      showDunningLevelDialogForm: [],
      showTest: false,
    };
  },
  methods: {
    onDunningLevelUpdated(updatedDunningLevel) {
      this.values[
          this.values.findIndex((el) => el["@id"] === updatedDunningLevel["@id"])
          ] = updatedDunningLevel;
    },
    onDunningLevelDeleted(deletedDunningLevel) {
      this.values.splice(
          this.values[
              this.values.findIndex((el) => el["@id"] === deletedDunningLevel['@id'])
              ],
          1
      );
    },
    dialogControl(id, close = false) {
      if (!close) {
        this.showDunningLevelDialogForm.push(id);
      } else {
        this.showDunningLevelDialogForm.splice(
            this.showDunningLevelDialogForm.indexOf(id),
            1
        );
      }
    },
  },
};
</script>
