<template>
    <v-row v-if="values">
      <v-col cols="12" md="6" v-for="(bankAccount, index) in values" :key="`bankAccount-${index}`">
        <v-card class="mt-5">
          <v-card-title>{{ bankAccount.name }}</v-card-title>
          <v-card-text>
            <b>IBAN:</b> {{ bankAccount.iban }}<br />
            <b>BIC:</b> {{ bankAccount.bic }}<br />
            {{ bankAccount.institute }}
            <div v-if="bankAccount.default === true">
              <br /><strong>{{ $t('isDefault') }}</strong>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="primary"
                small
                text
                @click.prevent="dialogControl(bankAccount['@id'])"
            >
              {{ $t("Edit") }}
            </v-btn>
            <BankAccountDialog
                v-model="showBankAccountDialogForm"
                :bankAccountItem="bankAccount"
                :handle-updated="onBankAccountUpdated"
                :handle-deleted="onBankAccountDeleted"
                :title="$t('EditBankAccount')"
                @close="dialogControl(bankAccount['@id'], true)"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </template>
  <script>
  import BankAccountDialog from "../../components/bankAccount/BankAccountDialog";

  export default {
    name: "BankAccountCard",
    components: {
      BankAccountDialog,
    },
    props: {
      values: {
        type: Array,
      },
    },
    data() {
      return {
        showBankAccountDialogForm: [],
        showTest: false,
      };
    },
    methods: {
      onBankAccountUpdated(updatedBankAccount) {
        this.values[
            this.values.findIndex((el) => el["@id"] === updatedBankAccount["@id"])
            ] = updatedBankAccount;
      },
      onBankAccountDeleted(deletedBankAccount) {
        this.values.splice(
            this.values[
                this.values.findIndex((el) => el["@id"] === deletedBankAccount['@id'])
                ],
            1
        );
      },
      dialogControl(id, close = false) {
        if (!close) {
          this.showBankAccountDialogForm.push(id);
        } else {
          this.showBankAccountDialogForm.splice(
              this.showBankAccountDialogForm.indexOf(id),
              1
          );
        }
      },
    },
  };
  </script>
